@if (employeeInfo()) {
  <mat-icon class="ec-icon">assignment_ind</mat-icon>
  <div class="ec-details">
    <span class="mat-body-2">
      {{employeeInfo().employeeName}} (<span class="mat-small">{{employeeInfo().employeeCode}}</span>)
    </span><br />
    <small>
      {{employeeInfo().employeeEmail}}
      @if (employeeInfo().departmentName) {
        | {{employeeInfo().departmentName}}
      }
    </small>
  </div>
}
