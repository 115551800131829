import { Component, input } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { EmployeeCardModel } from './employee-card.model';

@Component({
  selector: 'app-employee-card',
  imports: [
    MaterialModule,
  ],
  templateUrl: './employee-card.component.html',
  styleUrl: './employee-card.component.scss'
})
export class EmployeeCardComponent {
  readonly employeeInfo = input.required<EmployeeCardModel>();
}
